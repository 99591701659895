var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-page" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.msForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "condition-row", staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "功能类型：" } },
                [
                  _c("code-select", {
                    ref: "testSimpleMultipleCode",
                    attrs: {
                      codeType: "1018",
                      clearable: true,
                      collapseTags: true,
                      disabled: false
                    },
                    model: {
                      value: _vm.msForm.moduleCode,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "moduleCode", $$v)
                      },
                      expression: "msForm.moduleCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "菜单标题：" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: { placeholder: "", clearable: "" },
                    model: {
                      value: _vm.msForm.menuTitle,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "menuTitle",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.menuTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "菜单类型：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.menuTypeArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "menuTypeArr", $$v)
                        },
                        expression: "msForm.menuTypeArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("内部")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("外部")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "是否启用：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.ifEnableArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "ifEnableArr", $$v)
                        },
                        expression: "msForm.ifEnableArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 0 } },
                        [_vm._v("停用")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: {
                        click: function($event) {
                          return _vm.search(1)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      attrs: { icon: "el-icon-plus" },
                      on: {
                        click: function($event) {
                          return _vm.add()
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: { "row-key": "rid", data: _vm.dataList }
        },
        [
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(
                _vm.dataList,
                "moduleCodeName",
                "功能类型"
              ),
              prop: "moduleCodeName",
              label: "功能类型",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "300",
              prop: "moduleName",
              label: "功能描述",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(
                _vm.dataList,
                "menuTitle",
                "菜单标题"
              ),
              prop: "menuTitle",
              label: "菜单标题",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(_vm.dataList, "menuType", "菜单类型"),
              prop: "menuType",
              label: "菜单类型",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function({ row }) {
                  return [
                    _vm._v(" " + _vm._s(_vm.menuTypeMap[row.menuType]) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(_vm.dataList, "menuDesc", "菜单描述"),
              prop: "menuDesc",
              label: "菜单描述",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: 100,
              prop: "menuIcon",
              label: "菜单图标",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$zwxBase.verifyIsBlank(scope.row.menuIcon)
                      ? void 0
                      : [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              staticStyle: { "margin-right": "12px" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.openFilePreview(
                                    "附件",
                                    scope.row.menuIcon
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "outline chis-icon-outline-doc-search1",
                                staticStyle: { "margin-right": "4px" }
                              }),
                              _vm._v(" 查看 ")
                            ]
                          )
                        ]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: 100,
              prop: "menuPicture",
              label: "菜单图片",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$zwxBase.verifyIsBlank(scope.row.menuPicture)
                      ? void 0
                      : [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              staticStyle: { "margin-right": "12px" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.openFilePreview(
                                    "附件",
                                    scope.row.menuPicture
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "outline chis-icon-outline-doc-search1",
                                staticStyle: { "margin-right": "4px" }
                              }),
                              _vm._v(" 查看 ")
                            ]
                          )
                        ]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(
                _vm.dataList,
                "moduleLevelCode",
                "功能层级编码"
              ),
              prop: "moduleLevelCode",
              label: "功能层级编码",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(_vm.dataList, "orderNo", "菜单编号"),
              prop: "orderNo",
              label: "菜单编号",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(_vm.dataList, "linkUrl", "跳转地址"),
              prop: "linkUrl",
              label: "跳转地址",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: _vm.$system.calWidth(_vm.dataList, "menuCode", "菜单编码"),
              prop: "menuCode",
              label: "菜单编码",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ifEnable",
              label: "状态",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifEnable
                      ? _c(
                          "div",
                          { staticClass: "zwx-circle-text zwx-circle-normal" },
                          [_vm._v("启用")]
                        )
                      : _c(
                          "div",
                          { staticClass: "zwx-circle-text zwx-circle-finish" },
                          [_vm._v("停用")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "header-align": "left",
              "min-width": "200",
              align: "left",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.users.ifSuperAdmin || _vm.users.uid !== scope.row.uid
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.role(scope.row)
                              }
                            }
                          },
                          [_vm._v("角色分配")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text", icon: "el-icon-arrow-right" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        style: scope.row.ifEnable
                          ? "color: #e72b00 !important;"
                          : "",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$system.msgbox(
                              scope.row,
                              "提示",
                              scope.row.ifEnable
                                ? "确定要停用吗？"
                                : "确定要启用吗？",
                              "确定",
                              "取消",
                              _vm.enable
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.ifEnable ? "停用" : "启用"))]
                    ),
                    !scope.row.ifEnable
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.$system.msgbox(
                                  scope.row,
                                  "提示",
                                  "确定要删除吗？",
                                  "确定",
                                  "取消",
                                  _vm.del
                                )
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("empty-data-div", { attrs: { slot: "empty" }, slot: "empty" })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "12px 20px" } },
        [
          _c("base-pagination", {
            attrs: {
              parentPage: _vm.msForm.currentPage,
              pageSize: _vm.pageSize,
              total: _vm.total
            },
            on: { currentChange: _vm.currentChange }
          })
        ],
        1
      ),
      _c("file-preview", { ref: "filePreview" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }