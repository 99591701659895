<template>
  <div class="main-page">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row " style="display: flex">
        <el-form-item label-width="70px" label="功能类型：">
          <code-select ref="testSimpleMultipleCode" codeType="1018" v-model="msForm.moduleCode" :clearable="true" :collapseTags="true" :disabled="false" />
        </el-form-item>
        <el-form-item label-width="70px" label="菜单标题：">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.menuTitle" placeholder="" clearable />
        </el-form-item>
        <el-form-item label-width="70px" label="菜单类型：" >
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.menuTypeArr">
            <el-checkbox class="zwx-checkbox" :label="1">内部</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">外部</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="70px" label="是否启用：" >
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnableArr">
            <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div>
        <el-form-item>
          <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add()">添加</el-button>
        </el-form-item>
      </div>
    </el-form>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="dataList">
      <el-table-column :width="$system.calWidth(dataList, 'moduleCodeName', '功能类型')" prop="moduleCodeName" label="功能类型" header-align="center" align="left"></el-table-column>
      <el-table-column width="300" prop="moduleName" label="功能描述" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'menuTitle', '菜单标题')" prop="menuTitle" label="菜单标题" header-align="center" align="left"></el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'menuType', '菜单类型')" prop="menuType" label="菜单类型" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ menuTypeMap[row.menuType] }}
        </template>
      </el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'menuDesc', '菜单描述')" prop="menuDesc" label="菜单描述" header-align="center" align="left"></el-table-column>
      <el-table-column :width="100" prop="menuIcon" label="菜单图标" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsBlank(scope.row.menuIcon)"></template>
          <template v-else>
            <el-button class="zwx-button zwx-button-text-26" type="text" style="margin-right:12px" @click="openFilePreview('附件', scope.row.menuIcon)">
              <i class="outline chis-icon-outline-doc-search1" style="margin-right: 4px" />
              查看
            </el-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column :width="100" prop="menuPicture" label="菜单图片" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsBlank(scope.row.menuPicture)"></template>
          <template v-else>
            <el-button class="zwx-button zwx-button-text-26" type="text" style="margin-right:12px" @click="openFilePreview('附件', scope.row.menuPicture)">
              <i class="outline chis-icon-outline-doc-search1" style="margin-right: 4px" />
              查看
            </el-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'moduleLevelCode', '功能层级编码')" prop="moduleLevelCode" label="功能层级编码" header-align="center" align="left"></el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'orderNo', '菜单编号')" prop="orderNo" label="菜单编号" header-align="center" align="left"></el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'linkUrl', '跳转地址')" prop="linkUrl" label="跳转地址" header-align="center" align="left"></el-table-column>
      <el-table-column :width="$system.calWidth(dataList, 'menuCode', '菜单编码')" prop="menuCode" label="菜单编码" header-align="center" align="left"></el-table-column>
      <el-table-column prop="ifEnable" label="状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-normal" v-if="scope.row.ifEnable">启用</div>
          <div class="zwx-circle-text zwx-circle-finish" v-else>停用</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="left" min-width="200" align="left" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看</el-button> -->
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="role(scope.row)" v-if="users.ifSuperAdmin || users.uid !== scope.row.uid">角色分配</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="edit(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" :style="scope.row.ifEnable ? 'color: #e72b00 !important;' : ''" type="text" @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要停用吗？' : '确定要启用吗？', '确定', '取消', enable)">{{ scope.row.ifEnable ? '停用' : '启用' }}</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" v-if="!scope.row.ifEnable" type="danger" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', del)">
            删除
          </el-button>
        </template>
      </el-table-column>
      <empty-data-div slot="empty" />
    </el-table>

    <!-- 分页器部分 -->
    <div style="padding: 12px 20px;">
      <base-pagination :parentPage="msForm.currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
    </div>

    <!--文件预览弹框-->
    <file-preview ref="filePreview" />
  </div>
</template>

<script>
export default {
  name: 'NavigationListIndex',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      msForm: {
        moduleCode: '',
        menuTitle: '',
        menuTypeArr: [],
        ifEnableArr: [],
        currentPage: 1,
      },
      dataList: [],
      menuTypeMap: ['-', '内部', '外部'],
      pageSize: 18,
      total: 0,
    }
  },
  created() {},
  mounted() {
    this.search(1)
  },
  activated() {
    if (this.$route.params.fresh) this.search(1)
  },
  computed: {},
  methods: {
    searchTypeChange() {
      this.search(1)
    },
    search(currentPage) {
      this.$emit('loading', true)
      this.msForm.currentPage = currentPage
      let data = { ...this.msForm }
      this.$system.postJson(this.api + '/systematic/navigationList-1', data, true, true, this.searchSuccess, this.error)
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.dataList = data.dataList
        this.total = data.totalCount
        this.pageSize = data.pageSize
        this.$emit('loading', false)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },

    /**
     * 打开编辑功能导航
     */
    add() {
      this.$router.push({ name: 'NavigationEditIndex' })
    },
    /**
     * 打开编辑功能导航
     */
    edit(row) {
      this.$router.push({ name: 'NavigationEditIndex', params: { rid: row.rid } })
    },
    /**
     * 打开功能导航详情
     */
    detail(row) {
      this.$router.push({ name: 'NavigationDetailIndex', params: { rid: row.rid } })
    },

    currentChange(currentPage) {
      this.search(currentPage)
    },

    /**
     * 单个文件预览
     */
    openFilePreview(filename, filePath) {
      let previewFileList = []
      let item = {
        fileName: filename,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },

    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },

    /**
     * 启用停用导航
     */
    enable(row) {
      this.$emit('loading', true)
      let data = { rid: row.rid, ifEnable: !row.ifEnable }
      this.$system.post(this.api + '/systematic/modifyNavigationState-1', data, true, true, this.enableSuccess, this.error)
    },
    enableSuccess(data) {
      if (data.type === '00') {
        this.search(this.msForm.currentPage)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },

    //删除
    del(row) {
      let data = {
        rid: row.rid,
      }
      this.$system.postJson(
        this.api + '/systematic/deleteNavigation-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    //角色分配
    role(row) {
      this.$router.push({ name: 'NavigationRoleIndex', params: { rid: row.rid, menuTitle: row.menuTitle } })
    },
  },
}
</script>

<style lang="less" scoped></style>

<style lang="less"></style>
